import React from "react";
import { Link, graphql } from 'gatsby';
import NewsCard from "../components/news-card";
import Layout from "../layouts/layout";


const Documentaries  = ({data: {documentaries}}) =>  {

    const hero = {};
    hero.heroHeading = 'Publications';

    return(
        <Layout hero={hero}>
            <div className="container mx-auto mb-16 px-4">
                <ul className="flex-col sm:flex-row mb-8 leading-tight">
                    <div className="inline-block uppercase pt-6 mr-4 text-xs text-gray-800 font-bold font-aktivcd">
                        <Link to="/newsletter">MAN News</Link>
                    </div>
                    <div className="inline-block uppercase pt-6 mr-4 border-b-2 text-xs border-mane-yellow text-gray-800 font-bold font-aktivcd">
                        <Link to="/documentaries">Documentaries</Link>
                    </div>
                    <div className="inline-block uppercase pt-6 mr-4 text-xs text-gray-800 font-bold font-aktivcd">
                        <Link to="/case-studies">Case Studies</Link>
                    </div>
                    <div className="inline-block uppercase pt-6 mr-4 text-xs text-gray-800 font-bold font-aktivcd">
                        <Link to="/certificates">Certificates</Link>
                    </div>
                </ul>
                <div className="sm:flex flex-wrap -ml-4 mt-4">
                    {documentaries !== undefined && documentaries.edges.map(({node}) => (
                        <NewsCard key={node.id} node={node} external={true}/>
                    ))}
                </div>
                {/*
                <h2 class="text-3xl sm:text-5xl font-bold dark-blue leading-tight">Documentaries</h2>
                */}
            </div>
        </Layout>
    )};
export const query = graphql`
query getDocumentaries {
   documentaries: allContentfulDocumentaries (sort: {fields: id, order: DESC}) {
    edges {
        node {
           id
           title
           description
           video
           picture: previewImage {
             fluid {
               ...GatsbyContentfulFluid_withWebp_noBase64
             }
           }
        }
    }
  }
}

`;


export default Documentaries;


