import React from "react"
import { Link } from 'gatsby';
import Img from "gatsby-image";

const NewsCard = ({node, external}) => 
    (
        <div className="px-4 sm:w-1/2 lg:w-1/3 xl:w-1/3 project-card flex flex-col mb-12">
            <div class="border-shadow h-full">
                <Link to={node.video ? node.video : node.slug && `/news/${node.slug}`} 
                    target={external && `_blank`}
                >
                    {node.picture && 
                    <Img className="object-cover w-full h-full block" style={{height:"250px"}} alt={node.description}  alt={node.picture.title} fluid={node.picture.fluid} />
                    }
                    <div className="p-4 pb-0">
                        <h4 className="text-sm text-gray-800">{node.date}</h4>
                        <h3 className="text-gray-800 font-aktiv font-bold mb-4">{node.title}</h3>
                    </div>
                    <div class="mt-auto p-4 pt-0">
                        <p className="">{node.description}</p>
                    </div>
                </Link>
            </div>
        </div>
    )

export default NewsCard
